<template>
  <div class="recg-device">
    <component :is="activeComp" />
  </div>  
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'RecgDevice',
  components: {
    RecognitionLpr: () => import('@/components/AiBox/RecognitionLpr.vue'),
    RecognitionFR: () => import('@/components/AiBox/RecognitionFR.vue'),
    RecognitionOr: () => import('@/components/AiBox/RecognitionOr.vue'),
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState('aibox', ['aiMode']),
    activeComp() {
      if (this.aiMode === 'lpr') return 'RecognitionLpr'
      if (this.aiMode === 'or') return 'RecognitionOr'
      return 'RecognitionFR'
    }
  },
}
</script>

<style lang="scss" scoped>
.recg-device {
  padding: 16px 24px 16px 16px;
}
</style>